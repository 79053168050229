import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { ConfigProvider } from 'antd'
import { Baseline, theme, ThemeProvider } from 'xund-ui'
import { Question, Report } from 'health-check-api'
import { ErrorResponseType } from './components/ErrorBoundary'
import { LoadingIndicator } from './components/LoadingIndicator/LoadingIndicator'
import { PageNotFound } from './components/PageNotFound/PageNotFound'
import { ApiGatewayContext, AppStateContext, LocalizationContext, WebAppConfigContext } from './context'
import { useApiGateway, useLanguage, useLocalizations, useWebAppConfigLoader } from './hooks'
import { useAuthToken } from './hooks/useAuthToken'
import { CheckType, ResponseType, Translation, TranslationCodeLiteral, translationCodes } from './models'
import { QuestionContext } from './context/QuestionContext'
import { QuestionContext as HealthCheckQuestionContext } from './features/healthCheck/context/QuestionContext'
import { startQuestion } from './resources/startingQuestion'
import { startQuestion as healthCheckStartQuestion } from './features/healthCheck/mock/startQuestion'
import { CSS_VARS } from './resources/cssVariableConfig'
import { useApi } from './features/healthCheck/hooks/useApi'
import { FooterButtonState, StatusStep } from './features/healthCheck/models'

/**
 * A loader to load and provide data needed for the app
 *
 * @param props The props object
 * @param props.children The children object
 * @returns A wrapper with the required contexts
 */
export const LoaderLayer: FC<{
  children: ReactNode
}> = ({ children }) => {
  const token = useAuthToken()
  const { webAppConfig, isWebAppConfigLoading, webAppConfigError } = useWebAppConfigLoader(token)
  const { apiGateway, checkId, setCheckId, initialSymptom, setInitialSymptom, initialIllness, setInitialIllness } =
    useApiGateway(token)
  const { translations, isLocalizationsLoading, localizationsError } = useLocalizations(
    token && !isWebAppConfigLoading ? apiGateway : null,
  )
  const { currentLanguage, changeLanguage } = useLanguage()

  const [hideSkipButton, setHideSkipButton] = useState<boolean>(false)
  const [currentResponse, setCurrentResponse] = useState<ResponseType | null>(startQuestion)
  const [nextButtonLogic, setNextButtonLogic] = useState<(() => void) | null>(null)
  const [nextButtonI18nKey, setNextButtonI18nKey] = useState<TranslationCodeLiteral | ''>('general.confirm')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false)
  const [isReporting, setIsReporting] = useState<boolean>(false)
  const [isCheckFinished, setIsCheckFinished] = useState<boolean>(false)
  const [checkType, setCheckType] = useState<CheckType>('')
  const [mainContainerWidth, setMainContainerWidth] = useState<number>(0)
  const [isServicesOpen, setServicesOpen] = useState(false)
  const [isSideMenuOpen, setSideMenuOpen] = useState(false)
  const [isContentLibraryOpen, setContentLibraryOpen] = useState(false)

  //HEALTH-CHECK
  const [currentQuestion, setCurrentQuestion] = useState<Question>(healthCheckStartQuestion)
  const [report, setReport] = useState<Report | undefined>(undefined)
  const [healthCheckCheckId, setHealthCheckCheckId] = useState('')
  const [isHealthCheckLoading, setHealthCheckLoading] = useState<boolean>(false)
  const [selectedAnswer, setSelectedAnswer] = useState<string | string[] | null>(null)
  const api = useApi()
  const [statusStep, setStatusStep] = useState<StatusStep>(0)
  const [footerButtonState, setFooterButtonState] = useState<FooterButtonState>({
    nextButtonTitle: '',
    onNextButtonClick: null,
    isNextButtonDisabled: false,
    isNextButtonHidden: false,
    isSkipButtonHidden: true,
    onSkipButtonClick: null,
  })

  const checkTranslationIntegrity = useCallback((requestedTranslations: Translation[] | null): string[] => {
    if (requestedTranslations === null) {
      return []
    }
    return translationCodes.filter((code) => {
      const translationIndex = requestedTranslations.findIndex((t) => t.code === `xund.${code}`)
      return (
        translationIndex === -1 || (translationIndex !== -1 && !requestedTranslations[translationIndex]?.text.trim())
      )
    })
  }, [])

  useEffect(() => {
    if (!window.appStorage.getItem('xundWebAppLanguage') && !isWebAppConfigLoading) {
      changeLanguage(webAppConfig.defaultLanguage)
      window.appLocation.reload()
    }
  }, [isWebAppConfigLoading, webAppConfig, changeLanguage, currentLanguage])

  const isBaseLoading = useMemo(
    () => isWebAppConfigLoading || isLocalizationsLoading,
    [isLocalizationsLoading, isWebAppConfigLoading],
  )

  const isPageNotFoundError = useMemo(
    () => (webAppConfigError as ErrorResponseType)?.response?.data?.customType === 'NOT_FOUND_ERROR',
    [webAppConfigError],
  )

  const [isMissingTranslations, missingTranslationCodes] = useMemo(() => {
    const missingCodes = checkTranslationIntegrity(translations)
    return [missingCodes.length > 0, missingCodes]
  }, [checkTranslationIntegrity, translations])

  if (isBaseLoading) {
    return (
      <div style={{ height: '100%' }}>
        <LoadingIndicator />
      </div>
    )
  }

  if (isMissingTranslations) {
    throw Error(`Missing translations: ${missingTranslationCodes.join(', ')}`)
  }

  return (
    <ApiGatewayContext.Provider
      value={{
        apiGateway,
        checkId,
        setCheckId,
        initialSymptom,
        setInitialSymptom,
        initialIllness,
        setInitialIllness,
      }}
    >
      <LocalizationContext.Provider value={{ translations, isLocalizationsLoading, localizationsError }}>
        <WebAppConfigContext.Provider value={{ webAppConfig, isWebAppConfigLoading, webAppConfigError }}>
          <AppStateContext.Provider
            value={{
              isServicesOpen,
              setServicesOpen,
              isSideMenuOpen,
              setSideMenuOpen,
              isContentLibraryOpen,
              setContentLibraryOpen,
            }}
          >
            <QuestionContext.Provider
              value={{
                hideSkipButton,
                setHideSkipButton,
                currentResponse,
                setCurrentResponse,
                nextButtonLogic,
                setNextButtonLogic,
                nextButtonI18nKey,
                setNextButtonI18nKey,
                isLoading,
                setIsLoading,
                isNextButtonDisabled,
                setIsNextButtonDisabled,
                isReporting,
                setIsReporting,
                isCheckFinished,
                setIsCheckFinished,
                checkType,
                setCheckType,
                mainContainerWidth,
                setMainContainerWidth,
              }}
            >
              <HealthCheckQuestionContext.Provider
                value={{
                  report,
                  setReport,
                  currentQuestion,
                  setCurrentQuestion,
                  checkId: healthCheckCheckId,
                  setCheckId: setHealthCheckCheckId,
                  isLoading: isHealthCheckLoading,
                  setLoading: setHealthCheckLoading,
                  selectedAnswer,
                  setSelectedAnswer,
                  api,
                  footerButtonState,
                  setFooterButtonState,
                  statusStep,
                  setStatusStep,
                }}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Input: {
                        colorTextPlaceholder: CSS_VARS.DARK_GREY_COLOR,
                      },
                    },
                    token: {
                      screenSM: 577,
                      screenSMMin: 577,
                      screenXSMax: 576,
                    },
                  }}
                >
                  <ThemeProvider
                    theme={{
                      ...theme,
                      zIndices: { ...theme.zIndices, modal: 1100, overlay: 1000 },
                      radius: { ...theme.radius, lg: '15px' },
                      shadow: [...theme.shadow, '0px 5px 12px 0px rgba(0, 0, 0, 0.05)'],
                    }}
                  >
                    <Baseline />
                    {isPageNotFoundError ? <PageNotFound /> : children}
                  </ThemeProvider>
                </ConfigProvider>
              </HealthCheckQuestionContext.Provider>
            </QuestionContext.Provider>
          </AppStateContext.Provider>
        </WebAppConfigContext.Provider>
      </LocalizationContext.Provider>
    </ApiGatewayContext.Provider>
  )
}
