import React, { FC, useState } from 'react'
import {
  MedicalEngineReportGenericSpecialization,
  ClientQuickSolution,
  GenericSpecializationType,
} from 'medical-engine-api'
import { Card, Text } from 'xund-ui'
import classnames from 'classnames'
import { Col, Row } from 'antd'
import { useAppStateContext, useWebAppConfigContext } from '../../context'
import { CustomIcon } from '../CustomIcon/CustomIcon'
import { filterDuplicateValuesFromArray } from '../../utils/filterDuplicateValuesFromArray'
import { useScreenSize } from '../../hooks'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { QuickSolutionsInfoModal } from './QuickSolutionsInfoModal/QuickSolutionsInfoModal'
import styles from './QuickSolutions.module.less'
import { TransparencyRemoverContainer } from './QuickSolutions.styled'

/**
 * @param props The props object
 * @param props.quickSolutions The current quick solutions
 * @param props.specializations The current specializations
 * @param props.inReport Whether the component is used in report
 * @returns The QuickSolutions component
 */
export const QuickSolutions: FC<{
  quickSolutions: ClientQuickSolution[]
  specializations: MedicalEngineReportGenericSpecialization[]
  inReport?: boolean
}> = ({ quickSolutions, specializations, inReport }) => {
  const [selectedQuickSolution, setSelectedQuickSolution] = useState<ClientQuickSolution | null>(null)

  const { isMobileView } = useScreenSize()
  const { setServicesOpen, setSideMenuOpen } = useAppStateContext()
  const {
    webAppConfig: { illnessCheckIcd10Code: isDirectIllnessCheck },
  } = useWebAppConfigContext()

  /**
   * Returns the icon name for a generic specialization type
   *
   * @param type The generic specialization type in question
   * @returns Icon name
   */
  const getIcon = function (type: GenericSpecializationType): string {
    switch (type) {
      case 'HOSPITAL':
        return 'hospitalFilled'
      case 'DOCTOR':
        return 'doctorFilled'
      case 'PHARMACY':
        return 'pharmacyFilled'
      default:
        return ''
    }
  }

  const quickSolutionsElements = (
    <div className={styles.quickSolutionsRow}>
      {quickSolutions.map((quickSolution) => (
        <Card
          key={quickSolution.id}
          className={styles.quickSolutionsItem}
          onClick={() => setSelectedQuickSolution(quickSolution)}
          radius={'md'}
          shadow={4}
        >
          <TransparencyRemoverContainer>
            <CustomIcon
              className={styles.quickSolutionsIcon}
              icon={quickSolution.iconUrl}
              color={CSS_VARS.questionFontColor}
              colorizeByPlainCss
            />
          </TransparencyRemoverContainer>

          <Text
            style={{
              color: CSS_VARS.questionFontColor,
              paddingLeft: 1,
              paddingRight: 1,
              margin: '5px 0 0 0',
              fontSize: 12,
              fontFamily: 'Avenir Next',
              fontWeight: 400,
            }}
          >
            {quickSolution.name}
          </Text>
        </Card>
      ))}

      {selectedQuickSolution && (
        <QuickSolutionsInfoModal
          selectedQuickSolution={selectedQuickSolution}
          setSelectedQuickSolution={setSelectedQuickSolution}
        />
      )}
    </div>
  )

  const specializationsElements = (
    <div className={styles.specializationsRow}>
      {filterDuplicateValuesFromArray(specializations.map((item) => item.type)).map((item) => (
        <Card
          key={item}
          className={styles.specializationsItem}
          onClick={() => {
            if (isDirectIllnessCheck) {
              return
            }

            document.getElementById('header')?.scrollIntoView()

            if (!isMobileView) {
              setSideMenuOpen(true)
            }

            setServicesOpen(true)
          }}
          radius={'md'}
          shadow={4}
        >
          <CustomIcon
            className={styles.specializationsIcon}
            style={{ fill: CSS_VARS.chatBackgroundColor, color: CSS_VARS.questionFontColor }}
            icon={getIcon(item)}
          />

          <Text
            style={{
              color: CSS_VARS.questionFontColor,
              margin: '5px 0 0 0',
              fontSize: 12,
              fontFamily: 'Avenir Next',
              fontWeight: 400,
            }}
          >{`${item.slice(0, 1)}${item.toLowerCase().slice(1)}`}</Text>
        </Card>
      ))}
    </div>
  )

  return (
    <div className={classnames(styles.container, 'descriptionFadeIn')} style={{ paddingBottom: 30 }}>
      {(quickSolutions.length ?? 0) > 0 &&
        (inReport ? (
          <Row>
            <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
              {quickSolutionsElements}
            </Col>
          </Row>
        ) : (
          quickSolutionsElements
        ))}

      {(specializations.length ?? 0) > 0 && inReport ? (
        <Row>
          <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
            {specializationsElements}
          </Col>
        </Row>
      ) : (
        specializationsElements
      )}
    </div>
  )
}
