import React, { FC, ReactNode, useState, useMemo, CSSProperties } from 'react'
import { Button, Dialog, FlexAlign, FlexJustify, ResponsiveProp } from 'xund-ui'
import { useToggle, useWindowSize } from 'usehooks-ts'
import { useI18N, mobileMaxWidth } from '../../../hooks'
import styles from './BaseModal.module.less'

/**
 *
 * @param props The props object
 * @param props.title The title text or element to display in the Modal
 * @param props.children The main content of the Modal
 * @param props.description Small description of the contents of the Modal
 * @param props.onOk Method handling the OK button press
 * @param props.onCancel The method handling cancellation
 * @param props.okBtnId Optional ID for the OK button
 * @param props.okBtnTitle Optional custom text for the OK button
 * @param props.okBtnDisabled Logic to determine if the OK button is disabled or not
 * @param props.cancelBtnId Optional ID for the Cancel button
 * @param props.cancelBtnTitle Optional custom text for the Cancel button
 * @param props.cancelBtnDisabled Logic to determine if the Cancel button is disabled or not
 * @param props.onOpenChange Optional method that is called when the Modal is opened or closed
 * @param props.actionAlignment Vertical alignment of the Buttons
 * @param props.actionJustify Horizontal alignment of the Buttons
 * @param props.hasButtons This boolean decides whether we display buttons or not
 * @param props.contentStyle Optional custom style to add to the content of the Modal
 * @param props.contentClassName Optional custom class name for the content of the Modal
 * @returns The BaseModal component
 */
export const BaseModal: FC<{
  title?: string | ReactNode
  description?: string
  children?: ReactNode
  onOk?: Function
  onCancel: Function
  okBtnId?: string
  okBtnTitle?: string
  okBtnDisabled?: ResponsiveProp<boolean>
  cancelBtnId?: string
  cancelBtnTitle?: string
  cancelBtnDisabled?: ResponsiveProp<boolean>
  onOpenChange?: Function
  actionAlignment?: FlexAlign
  actionJustify?: FlexJustify
  hasButtons?: boolean
  contentStyle?: CSSProperties
  contentClassName?: string
}> = ({
  title,
  description,
  children,
  onOk,
  onCancel,
  okBtnId,
  okBtnTitle,
  okBtnDisabled,
  cancelBtnId,
  cancelBtnTitle,
  cancelBtnDisabled,
  onOpenChange,
  actionAlignment = 'center',
  actionJustify = 'center',
  hasButtons = true,
  contentStyle,
  contentClassName,
}) => {
  const { i18n } = useI18N()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, toggleOpen] = useToggle(true)
  const { width: windowWidth } = useWindowSize()
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  return (
    <Dialog.Root
      id={styles.baseModal}
      open={isOpen}
      onOpenChange={() => {
        onOpenChange ? onOpenChange() : onCancel()
        toggleOpen()
      }}
    >
      <Dialog.Content radius={'lg'}>
        <Dialog.Title>{title}</Dialog.Title>
        {description && <Dialog.Description>{description}</Dialog.Description>}
        <div className={[styles.content, contentClassName].join(' ')} style={contentStyle}>
          {children}
        </div>
        {hasButtons && (
          <Dialog.Actions
            align={actionAlignment}
            justify={actionJustify}
            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <Button
              id={cancelBtnId}
              className={styles.button}
              disabled={isLoading || cancelBtnDisabled}
              onClick={() => onCancel()}
              variant="outlined"
            >
              {cancelBtnTitle ?? i18n('general.modal.cancel')}
            </Button>
            {onOk ? (
              <Button
                id={okBtnId}
                className={styles.button}
                disabled={isLoading || okBtnDisabled}
                onClick={async () => {
                  setIsLoading(true)
                  await onOk()
                }}
              >
                {okBtnTitle ?? i18n('general.modal.submit')}
              </Button>
            ) : (
              <></>
            )}
          </Dialog.Actions>
        )}
        {!hasButtons && <div className={styles.bottomMargin}></div>}
      </Dialog.Content>
    </Dialog.Root>
  )
}
