import styled from '@emotion/styled'

export const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (orientation: portrait) and (max-width: @mobile-max-width) {
    padding: 0 5px 5px 5px;
  }
`
