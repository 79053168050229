import React, { FC, ReactNode, useCallback } from 'react'
import replace from 'react-string-replace'
import { useI18N } from '../../../../hooks'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { ChatText } from '../../../../components/General/ChatText/ChatText'
import { StyledButton } from '../../../../components/General/StyledButton/StyledButton'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { ButtonContainer } from './LegalDisclaimer.styled'

interface Props {
  onStartCheck: () => void
  onCancelCheck: () => void
  termsAndConditionsUrl?: string
  privacyPolicyUrl?: string
}

export const LegalDisclaimer: FC<Props> = (props) => {
  const { onStartCheck, onCancelCheck, termsAndConditionsUrl, privacyPolicyUrl } = props
  const { i18n, getCustomTranslationWithLocalFallback } = useI18N()

  const getTermsContent = useCallback(() => {
    const termsAndConditionsUrlLink = (
      <a
        key="termsAndConditionsUrl"
        style={{ color: CSS_VARS.linkColor }}
        rel="noopener noreferrer"
        href={termsAndConditionsUrl}
        target="_blank"
      >
        {i18n('general.generalTerms')}
      </a>
    )

    const privacyPolicyUrlLink = (
      <a
        key="privacyPolicyUrl"
        style={{ color: CSS_VARS.linkColor }}
        rel="noopener noreferrer"
        href={privacyPolicyUrl}
        target="_blank"
      >
        {i18n('termsAndConditions.dataPrivacy')}
      </a>
    )

    let termsAndConditions: string | ReactNode[] = getCustomTranslationWithLocalFallback(
      'termsAndConditionsExplanatoryText',
    )

    termsAndConditions = replace(
      termsAndConditions,
      /(\{generalTerms\})/g,
      () => termsAndConditionsUrlLink,
    ) as ReactNode[]

    termsAndConditions = replace(termsAndConditions, /(\{dataPrivacy\})/g, () => privacyPolicyUrlLink) as ReactNode[]

    termsAndConditions = replace(termsAndConditions, /(<br>)/g, (_, index) => <br key={index} />) as ReactNode[]

    return termsAndConditions
  }, [getCustomTranslationWithLocalFallback, i18n, privacyPolicyUrl, termsAndConditionsUrl])

  return (
    <WrapperWithTitleAndAnimation title={i18n('general.generalTerms')} isAnimated animationStartsWhen={true}>
      <ChatText title={getTermsContent()} style={{ fontSize: CSS_VARS.chatFontSize, textAlign: 'center' }} />

      <ButtonContainer>
        <StyledButton type="outlined" title={i18n('general.accept')} onClick={onStartCheck} />
        <StyledButton
          type="outlined"
          title={i18n('general.doNotAccept')}
          style={{ marginTop: 10 }}
          onClick={onCancelCheck}
        />
      </ButtonContainer>
    </WrapperWithTitleAndAnimation>
  )
}
